import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { Helmet } from "react-helmet";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { shuffle } from "lodash";
import { useEffect } from "react";
import { useRef } from "react";

import ArrowsBadgeIcon from "@js/component/icon/badge/ArrowsBadgeIcon";
import Box from "@js/component/Box";
import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";
import Main from "@js/component/Main";

import * as classNames from "@css/component/template/Advisors.module.scss";
import BreadCrumbs from "@js/component/BreadCrumbs";

/**
 * @type AllAdvisorData
 */
type AllAdvisorData = {
    allAdvisor: {
        edges: {
            node: {
                companyImageFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    }
                };
                companyName: string;
                completedRequests: number;
                url: string;
            };
        }[];
    }
};

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    logoPlaceholder: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type Data
 */
type Data = AllAdvisorData & LogoPlaceholderData;


/**
 * @type MetaProps
 */
type MetaProps = {
    pageContext: {
        metaTitle: string;
        metaDescription: string;
        metaKeywords: string;
        metaUrl: string;
        title_h1: string;
    }
}

/**
 * @const Index
 */
const Index = (props: MetaProps) => {
    const {
        pageContext
    } = props;

    const data: Data = useStaticQuery(graphql`
        query {
            allAdvisor {
                edges {
                    node {
                        companyImageFile {
                            childImageSharp {
                                gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                            }
                        }
                        companyName
                        completedRequests
                        url
                    }
                }
            }
            logoPlaceholder: file(relativePath: { eq: "logo-placeholder.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                }
            }
        }
    `);

    const sortedAdvisors = useRef(data.allAdvisor.edges.sort((a, b) => a.node.completedRequests < b.node.completedRequests ? 1 : -1));

    return (
        <Main>
            <Helmet>
                <title>Alle energielabel-adviseurs</title>
                { pageContext.metaDescription &&<meta content={pageContext.metaDescription} name="description" /> }
                { pageContext.metaDescription &&<meta content={pageContext.metaDescription} property="og:description" /> }
                { pageContext.metaTitle && <meta content={pageContext.metaTitle} property="og:title" /> }
                { pageContext.metaKeywords && <meta content={pageContext.metaKeywords} property="og:keywords" /> }
                { pageContext.metaUrl && <meta content={pageContext.metaUrl} property="og:url" /> }
                <meta content="woninglabel.nl" property="og:site_name" />
            </Helmet>
            <section className={ classNames.advisors }>
                <div className={ classNames.container }>
                    <BreadCrumbs
                        breadCrumbs={ [{
                            text: pageContext.title_h1 ? pageContext.title_h1 : 'Alle energielabel-adviseurs',
                            url: "energielabel-adviseurs"
                        }] }
                        variant="advisors"
                        parents="over-ons"
                    />
                    <Heading className={ classNames.heading } variant="large">
                        { pageContext.title_h1 ? pageContext.title_h1 : 'Alle energielabel-adviseurs' }
                    </Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box>
                                <Heading className={ classNames.heading } element="h2" variant="medium">
                                    Energielabel-adviseurs door heel Nederland om uit te kiezen
                                </Heading>
                                <p className={ classNames.paragraph }>
                                    Hier vind je alle energielabel-adviseurs die bij Woninglabel.nl zijn aangesloten.
                                    Woninglabel.nl biedt momenteel de meest complete vergelijking aan
                                    energielabel-adviseurs van Nederland.
                                    Hiernaast zijn alle door ons weergegeven energielabel-adviseurs gediplomeerd en gecertificeerd.
                                    Tevens werken wij bij Woninglabel.nl samen met de grootste certificerende instelling
                                    van de energieadvies-branche en ziet toe op kwaliteitswaarborging van
                                    geleverde producten en diensten.
                                </p>
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            { sortedAdvisors.current.map(({ node }, index) => (
                                <Box className={ classNames.box } key={ index }>
                                    <Link to={ `/over-ons/energielabel-adviseurs/${ node.url }` }>
                                        { node.companyImageFile?.childImageSharp?.gatsbyImageData &&
                                        <GatsbyImage
                                            alt={ node.companyName ? node.companyName : "company" }
                                            className={ classNames.image }
                                            image={ node.companyImageFile.childImageSharp.gatsbyImageData }
                                        />
                                        }
                                        { !(node.companyImageFile?.childImageSharp?.gatsbyImageData) &&
                                        <GatsbyImage
                                            alt={ node.companyName ? node.companyName : "company" }
                                            className={ classNames.image }
                                            image={ data.logoPlaceholder.childImageSharp.gatsbyImageData }
                                        />
                                        }
                                    </Link>
                                </Box>
                            )) }
                        </div>
                        <div className={ classNames.blockThree }>
                            <Box>
                                <header className={ classNames.header }>
                                    <ArrowsBadgeIcon className={ classNames.icon } />
                                    <Heading className={ classNames.heading } element="h2">
                                        Vergelijk energielabel-adviseurs.
                                    </Heading>
                                </header>
                                <p className={ classNames.paragraph }>
                                    Vergelijk op prijs, kwaliteit en snelheid op basis van jouw gegevens.
                                </p>
                                <LinkButton className={ classNames.linkButton } to="/vergelijk">
                                    <span>Vergelijk nu</span>
                                    <LongArrowRightIcon className={ classNames.icon } />
                                </LinkButton>
                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </Main>
    );
};

export default Index;
